
import Vue from 'vue'
import { favicons, images, metadata } from '~/assets/ts/utils/metadata'

export default Vue.extend({
  head(this: any) {
    return metadata(this, {
      images: images(require('@/assets/images/ogImage.png')),
      description: this.$t('sa.subtitle'),
      favicons: favicons('sa'),
      bodyAttrs: {
        class: this.modalOpen ? 'overflow-hidden' : '',
      },
    })
  },
  computed: {
    modalOpen(): boolean {
      return this.$store.getters['site/modalOpen']
    },
  },
})
